/* css reset */
* {box-sizing: border-box;}
*:before, *:after {box-sizing: border-box;}
* {margin: 0; padding: 0;}
ul, ol {list-style: none;}
a { color: inherit; text-decoration: none;}
img { max-width: 100%; max-height: 100% }
button {outline: none; border: none; cursor: pointer}

/* animation */
@keyframes delayedFadeIn {
  0% {opacity: 0;}
  50% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes pulse {
  0% {transform: scaleX(1)}
  50% {transform: scale3d(1.05, 1.05, 1.05)}
  to {transform: scaleX(1)}
}

/* style */
body {
  background-color: #333;
  max-width: 100%;
  overflow: hidden;
  font-family: "Microsoft Yahei Light", "Microsoft Yahei", PingFangSC-Regular, Helvetica, sans-serif, "等线";
  position: relative;
  cursor: default;
}
.bodyBox {
  z-index: -2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.25s;
}
.bgIMG {
  z-index: -3;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s, transform 0.25s, filter 0.25s;
  backface-visibility: hidden; /* 解决页面切换时的黑边闪烁问题 */
}
.bgIMG.inputFocus {
  filter: blur(10px);
  transform: scale(1.1);
}
.cover {
  z-index: -1;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0,0,0,0.3); */
  background-image: radial-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%), radial-gradient(rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.3) 166%);
  transition: all 0.25s;
}

main .SearchBox {
  /*border: 1px solid red;*/
  /*background-color: rgba(100,100,255,.5);*/
  z-index: 9;
  position: relative;
  top: 14vh;
  left: 0;
  transition: all 0.25s;
  /*width: 100%;*/
  /*height: 150px;*/
}

.time {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  /*margin-left: -50px;*/
  width: 100px;
  text-align: center;
  transition: all 0.25s;
}
.timeText {
  color: #fff;
  font-size: 36px;
  font-weight: inherit;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
  cursor: pointer;
  transition: all 0.25s;
  line-height: 20px;
  /* 每次加载页面，time延迟缓入 */
  /*animation-name: delayedFadeIn;*/
  /*animation-duration: 1s;*/
  /*animation-iteration-count: 1;*/
  /*animation-delay: 0s;*/
}

#input0 {
  opacity: 1;
  position: absolute;
  top: 60px;
  /*left: calc(50% - 115px);*/
  left: 50%;
  transform: translateX(-50%);
  width: 230px;
  outline: none;
  border: none;
  /*border: 1px solid rgba(255, 255, 255, 0.3);*/
  padding: 13px 15px;
  border-radius: 30px;
  color: rgba(255, 255, 255, 0.8);
  font-size: small;
  font-weight: normal;
  font-family: "Microsoft Yahei", sans-serif;
  text-align: center;
  background-color: rgba(255, 255, 255, .25);
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: all 0.25s;
  /*filter: blur(10px);*/
  /*transition: color 0.25s, background-color 0.25s, box-shadow 0.25s, left 0.25s, opacity 0.25s, top 0.25s, width 0.25s;*/
}
#input0:hover {width: 500px; background-color: rgba(255, 255, 255, .5);}
#input0::-webkit-input-placeholder { color: #fff; transition: all 0.25s;}
#input0:hover::-webkit-input-placeholder {color: rgba(0, 0, 0, 0.5);}
#input0.focus::-webkit-input-placeholder {color: transparent;}
#input0.focus {
  background-color: rgba(255, 255, 255, .9);
  color: #333;
  width: 500px;
}
#input0.keyShow {
  opacity: 0;
}

.searchOptBox {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: nowrap;
  transition: all 0.25s;
}
.searchOptBox.show {
  visibility: visible;
  opacity: 1;
  top: 124px;
}
.searchOpt.selected {
  background-color: rgba(255, 255, 255, .5);
}
.searchOpt {
  color: rgba(255, 255, 255, .9);
  font-size: 14px;
  border-radius: 30px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  padding: 5px 30px;
  background-color: rgba(255, 255, 255, .2);
  transition: all 0.25s;
}
.searchOpt .icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.keyboardBox {
  border: 1px solid transparent;
  z-index: 9;
  position: absolute;
  top: 45vh;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.25s;
  display: none;
  opacity: 0;
  /*backdrop-filter: blur(20px);*/
}
.keyboardBox .row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 30px;
  transition: all 0.25s;
}
.keyboardBox .row li {
  border-radius: 9px;
  width: 70px;
  height: 56px;
  padding-left: 3px;
  padding-right: 3px;
  margin-right: 10px;
  margin-left: 10px;
  /*background-color: #fff;*/
  font-size: 16px;
  background: linear-gradient(to bottom, #fff 0%, #E3E3E3 100%);
  border: 1px solid #fff;
  color: #4d4d4d;
  font-weight: bold;
  box-shadow: 0 0 0 1px #AAAAAA, 0 2px 0 2px #C0C0C0, 0 3px 0 2px #c9c9c9;
  /*box-shadow: 0 0 0 1px #ededed, 0 0 0 2px #fff, 0 3px 0 2px #f1f0ef;*/
  text-transform: uppercase;
  position: relative;
  cursor: default;
}
.keyboardBox .row li span {
  position: absolute;
  top: 2px;
  left: 6px;
}
.keyboardBox .row li:hover button {
  opacity: 1;
}
.keyboardBox .row li button {
  position: absolute;
  top: 3px;
  right: 5px;
  font-size: 14px;
  opacity: 0;
  transition: all 0.25s;
  border: none;
  background-color: transparent;
}
.keyboardBox .row li img {
  position: absolute;
  bottom: 2px;
  left: 5px;
  max-width: 14px;
  max-height: 18px;
  object-fit: cover;
  /*background-color: #fff;*/
  border-radius: 3px;
}
.keyboardBox .row li.key.down{
  margin: 0 8px -2px 12px
}


footer {
  color: rgba(255, 255, 255, .6);
  text-align: center;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  font-size: 12px;
}
.footerBox {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 3px 10px;
  border-radius: 6px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /*max-width: 100%;*/
}
.footerBox .link {
  transition: all 0.3s;
}
.footerBox .link:hover {
  color: #fff;
}

.file {
  position: fixed;
  top: 40px;
  right: 60px;
  /*background: transparent;*/
  border-radius: 4px;
  padding: 2px 2px;
  cursor: pointer;
  font-size: 14px;
  color: rgba(255,255,255,.4);
  transition: all 0.25s;
  opacity: 0;
}
.file .icon {
  width: 22px;
  height: 22px;
  /*object-fit: cover;*/
  fill: currentColor;
  overflow: hidden;
  transition: all 0.25s;
}
.file input:hover + .icon {
  color: rgba(255,255,255,.7);
}
.file input[type="file"] {
  opacity: 0;
  cursor: pointer;
  width: 24px;
  height: 24px;
  font-size: 0;
  position: absolute;
}


@media (max-width: 500px) {
  .keyboardBox {
    display: none !important;
  }
  #input0 {
    width: 200px;
  }
  #input0.focus {
    width: 300px;
  }
  .footerBox{
    padding: 3px 9px;
  }
  .file{
    top: 20px;
    right: 20px;
  }
}
